<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <b-img
            :src="logo"
            style=""
            height="80"
          />
        </b-link>

        <b-card-title class="mb-1">
          Esqueceu a sua Senha? 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Informe seu email e enviaremos instruções para resetar a sua senha.
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="Usuário"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Usuário"
                rules="required"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              Enviar link
            </b-button>
          </b-form>
        </validation-observer>
        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'login'}">
            <feather-icon icon="ChevronLeftIcon" /> Voltar para o login
          </b-link>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton, BCard, BCardText, BCardTitle, BForm, BFormGroup, BFormInput, BImg, BLink,
} from 'bootstrap-vue'
import { email, required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BImg,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      logo: require('@/assets/images/logo/logo.png'),
      userEmail: '',
      // validation
      required,
      email,
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          const response = await this.$http.post('auth/recovery-password', { email: this.userEmail })
          if (response.error) {
            this.$notify.error(response.error_message)
            return
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Um email com instruções foi enviado para o email do usuário informado.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          setTimeout(() => {
            this.$router.push('/')
          }, 300)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
